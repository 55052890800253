import { ConvertDateTimeToUTCFromAnyTimeZone, ConvertDateTimeFromAnyTimeZoneToUTC } from '@/common/global/functions'

const isSelectedDateIsGreaterThanTodayDate = (day, minDates) => {
  const selectedDate = new Date(day.id)
  const minDate = new Date(minDates)
  selectedDate.setHours(0, 0, 0, 0)
  minDate.setHours(0, 0, 0, 0)
  return selectedDate < minDate
}
const convertToSchedulerFormat = (array, deviceIds) => {
  const temparray = []
  array.forEach(element => {
    element.value.forEach(slots => {
      temparray.push({
        start_date_time: `${element.key}T${slots.start_time}`,
        end_date_time: `${element.key}T${slots.end_time}`,
      })
    })
  })
  return deviceIds.flatMap(deviceID => temparray.map(schedule => ({
    ...schedule,
    device_id: deviceID.id,
    cpm: deviceID.cpm,
    revenue_percentage: deviceID.revenue_percentage,
    free_impressions: parseFloat(deviceID.impressions),
  })))
}

const convertToSchedulerFormatToUTC = async (timeZone, array) => {
  const temparray = []
  array.forEach(element => {
    temparray.push({
      start_date_time: ConvertDateTimeFromAnyTimeZoneToUTC(element.start_date_time, timeZone),
      end_date_time: ConvertDateTimeFromAnyTimeZoneToUTC(element.end_date_time, timeZone),
      device_id: element.device_id,
      cpm: parseFloat(element.cpm),
      revenue_percentage: parseFloat(element.revenue_percentage),
      free_impressions: parseFloat(element.free_impressions),
    })
  })
  return temparray
}

const convertFromUTCToSchedulerTimeZoneFormat = async (timeZone, array) => {
  const temparray = []
  array.forEach(element => {
    temparray.push({
      ...element,
      start_date_time: ConvertDateTimeToUTCFromAnyTimeZone(element.start_date_time, timeZone),
      end_date_time: ConvertDateTimeToUTCFromAnyTimeZone(element.end_date_time, timeZone),
    })
  })
  return temparray
}

const convertedDataComingFromSchedulerApi = async data => {
  const groupedData = {}

  data.forEach(item => {
    const startDate = item.start_date_time.split('T')[0]
    const startTime = item.start_date_time.split('T')[1].split('.')[0]
    const endTime = item.end_date_time.split('T')[1].split('.')[0]

    if (!groupedData[startDate]) {
      groupedData[startDate] = []
    }

    const isDuplicate = groupedData[startDate].some(slot => slot.start_time === startTime && slot.end_time === endTime)
    if (!isDuplicate) {
      groupedData[startDate].push({
        start_time: startTime,
        end_time: endTime,
      })
    }
  })

  return Object.keys(groupedData).map(key => ({
    key,
    value: groupedData[key],
  }))
}
const getThreeDaysAheadDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 2)
  return date
}
const convertDateToISOString = async date => {
  const newDate = new Date(date)
  return newDate.toISOString()
}
const convertToSchedulerFormatToUTCForInternal = async (timeZone, array) => {
  const temparray = []
  array.forEach(element => {
    temparray.push({
      start_date_time: ConvertDateTimeFromAnyTimeZoneToUTC(element.start_date_time, timeZone),
      end_date_time: ConvertDateTimeFromAnyTimeZoneToUTC(element.end_date_time, timeZone),
    })
  })
  return temparray
}

const convertFromUTCToSchedulerTimeZoneFormatForInternal = async (timeZone, array) => {
  const temparray = []
  array.forEach(element => {
    temparray.push({
      ...element,
      start_date_time: ConvertDateTimeToUTCFromAnyTimeZone(element.start_date_time, timeZone),
      end_date_time: ConvertDateTimeToUTCFromAnyTimeZone(element.end_date_time, timeZone),
    })
  })
  return temparray
}

const concatenateDateWithTimeSlotsForInternal = async array => {
  const temparray = []
  array.forEach(element => {
    element.value.forEach(slots => {
      temparray.push({
        start_date_time: `${element.key}T${slots.start_time}`,
        end_date_time: `${element.key}T${slots.end_time}`,
      })
    })
  })
  return temparray
}

const getAllTimezones = () => Intl.supportedValuesOf('timeZone')

export {
  isSelectedDateIsGreaterThanTodayDate,
  convertToSchedulerFormat,
  convertedDataComingFromSchedulerApi,
  getThreeDaysAheadDate,
  convertDateToISOString,
  convertToSchedulerFormatToUTC,
  convertFromUTCToSchedulerTimeZoneFormat,
  convertToSchedulerFormatToUTCForInternal,
  convertFromUTCToSchedulerTimeZoneFormatForInternal,
  getAllTimezones,
  concatenateDateWithTimeSlotsForInternal,
}
